body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

:root {
    --color-surface: white;
    --color-solid: #231f20;
    --color-primary: #ac9b47;
    --color-gentle: #dad1a6;
    --color-strong: #868136;

    --break-small: 880px;
}

html {
    font-size: 1rem;
}

body {
    font-family: 'mr_eaves_sans';
    font-size: 1.3rem;
}

h1 {
    font-family: 'Butler';
    font-style: normal;
    font-weight: 200;
}

.PageArea {
    position: absolute;
    margin-top: 3.8rem;
    margin-left: 27.25rem;
    margin-right: 8rem;
    max-width: 40rem;
}

.Post p {
    font-family: 'mr_eaves_sans';
}

.Post p a {
    color: #ac9b47;
    color: var(--color-primary);
}

.Post h1 {
    font-family: 'Butler';
    font-style: normal;
    font-weight: 200;
}

.HeaderLogo.mobile {
    display: none;
}

.HeaderMenuButton {
    display: none;
}

.Nav {
    position: fixed;
    width: 14.4375rem;
    height: 15.875rem;
    padding: 1.75rem 0 0 0;
    background-color: white;
    background-color: var(--color-surface);
    border-style: solid;
    border-width: 0.375rem;
    margin-top: 9.625rem;
    margin-left: -0.375rem;
    -webkit-border-image: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#ac9b47),
        to(rgba(0,0,0,0))
    ) 1 1 1 0;
    -webkit-border-image: linear-gradient(
        to bottom,
        #ac9b47,
        rgba(0,0,0,0)
    ) 1 1 1 0;
            border-image: -webkit-gradient(
        linear,
        left top, left bottom,
        from(#ac9b47),
        to(rgba(0,0,0,0))
    ) 1 1 1 0;
            border-image: linear-gradient(
        to bottom,
        #ac9b47,
        rgba(0,0,0,0)
    ) 1 1 1 0;
    -webkit-border-image: -webkit-gradient(
        linear,
        left top, left bottom,
        from(var(--color-primary)),
        to(rgba(0,0,0,0))
    ) 1 1 1 0;
    -webkit-border-image: linear-gradient(
        to bottom,
        var(--color-primary),
        rgba(0,0,0,0)
    ) 1 1 1 0;
            border-image: -webkit-gradient(
        linear,
        left top, left bottom,
        from(var(--color-primary)),
        to(rgba(0,0,0,0))
    ) 1 1 1 0;
            border-image: linear-gradient(
        to bottom,
        var(--color-primary),
        rgba(0,0,0,0)
    ) 1 1 1 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
}

.NavItem {
    height: 3.5rem;
    vertical-align: middle;
    color: #ac9b47;
    color: var(--color-primary);
    padding: 0 2.875rem 0 3.75rem;
    overflow: visible;
    cursor: pointer;
}

.NavItemText {
    height: 2.5rem;
    font-size: 1.875rem;
    text-align: right;
    padding: 0.5rem 0.3rem 0.5rem 0;
    border-bottom: 1px solid #dad1a6;
    border-bottom: 1px solid var(--color-gentle);
}

.NavItem:hover {
    color: #868136;
    color: var(--color-strong);
    text-shadow: 0.1rem 0.1rem 0.3rem #ac9b47;
    text-shadow: 0.1rem 0.1rem 0.3rem var(--color-primary);
}

.NavItem.selected {
    font-weight: bold;
    color: #dad1a6;
    color: var(--color-gentle);
}

.NavItem.selected::after {
    content: '';
    display: block;
    width: 16.125rem;
    height: 3.3rem;
    margin: -3.4rem 0 0 -3.75rem;
    background-color: #ac9b47;
    background-color: var(--color-primary);
}

.RsvpForm {
    box-shadow: 0px 2px 57px #ac9b4744;
    border-style: solid;
    border-width: 0.25rem;
    -webkit-border-image: -webkit-gradient(
        linear,
        left top, left bottom,
        from(white),
        color-stop(26%, #868136),
        to(white)
    ) 0 1 0 1;
    -webkit-border-image: linear-gradient(
        to bottom,
        white 0%,
        #868136 26%,
        white 100%
    ) 0 1 0 1;
            border-image: -webkit-gradient(
        linear,
        left top, left bottom,
        from(white),
        color-stop(26%, #868136),
        to(white)
    ) 0 1 0 1;
            border-image: linear-gradient(
        to bottom,
        white 0%,
        #868136 26%,
        white 100%
    ) 0 1 0 1;
    -webkit-border-image: -webkit-gradient(
        linear,
        left top, left bottom,
        from(white),
        color-stop(26%, var(--color-strong)),
        to(white)
    ) 0 1 0 1;
    -webkit-border-image: linear-gradient(
        to bottom,
        white 0%,
        var(--color-strong) 26%,
        white 100%
    ) 0 1 0 1;
            border-image: -webkit-gradient(
        linear,
        left top, left bottom,
        from(white),
        color-stop(26%, var(--color-strong)),
        to(white)
    ) 0 1 0 1;
            border-image: linear-gradient(
        to bottom,
        white 0%,
        var(--color-strong) 26%,
        white 100%
    ) 0 1 0 1;
    padding: 1rem 1.7rem;
    margin-bottom: 2rem;
}

.RsvpForm input[type="text"] {
    font-family: 'mr_eaves_sans';
    font-size: 1.2rem;
    display: block;
    padding: 0.4rem 0.8rem;
    margin: 1rem 0rem;
    width: 100%;
    box-sizing: border-box;
}

.RsvpForm textarea {
    font-family: 'mr_eaves_sans';
    font-size: 1.2rem;
    display: block;
    padding: 0.4rem 0.8rem;
    margin: 1rem 0rem;
    width: 100%;
    box-sizing: border-box;
}

.RsvpForm input::-webkit-input-placeholder {
    color: #ac9b47;
    color: var(--color-primary);
}

.RsvpForm input::-moz-placeholder {
    color: #ac9b47;
    color: var(--color-primary);
}

.RsvpForm input:-ms-input-placeholder {
    color: #ac9b47;
    color: var(--color-primary);
}

.RsvpForm input::-ms-input-placeholder {
    color: #ac9b47;
    color: var(--color-primary);
}

.RsvpForm input::placeholder {
    color: #ac9b47;
    color: var(--color-primary);
}

.RsvpForm textarea::-webkit-input-placeholder {
    color: #ac9b47;
    color: var(--color-primary);
}

.RsvpForm textarea::-moz-placeholder {
    color: #ac9b47;
    color: var(--color-primary);
}

.RsvpForm textarea:-ms-input-placeholder {
    color: #ac9b47;
    color: var(--color-primary);
}

.RsvpForm textarea::-ms-input-placeholder {
    color: #ac9b47;
    color: var(--color-primary);
}

.RsvpForm textarea::placeholder {
    color: #ac9b47;
    color: var(--color-primary);
}
 
 .customRadio {
    display: inline-block;
    position: relative;
    padding-left: 2rem;
    margin-bottom: 1rem;
    margin-left: 3rem;
    cursor: pointer;
    font-size: 1.3rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

/* Hide the browser's default radio button */
.customRadio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

/* Create a custom radio button */
.radioButton {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #dad1a6;
    background-color: var(--color-gentle);
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .customRadio:hover input ~ .radioButton {
    background-color: #ac9b47;
    background-color: var(--color-primary);
  }
  
  /* When the radio button is checked, add a blue background */
  .customRadio input:checked ~ .radioButton {
    background-color: #868136;
    background-color: var(--color-strong);
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .radioButton:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .customRadio input:checked ~ .radioButton:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .customRadio .radioButton:after {
    top: 5px;
    left: 5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: white;
  }

.bottomEdgeButton {
    font-family: 'mr_eaves_sans';
    font-size: 1.375rem;
    letter-spacing: 0.8rem;
    display: block;
    position: relative;
    width: 16rem;
    padding: 0.6rem;
    background-color: #ac9b47;
    background-color: var(--color-primary);
    margin: 1.125rem auto -2rem auto;
    text-align: center;
    color: white;
    border: none;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.bottomEdgeButton .innerBorder {
    border: solid 0.125rem white;
}

.RegistryGallery a:link {
    display: block;
    width: 16rem;
    text-decoration: none;
}

.RegistryGallery a:hover {
    display: block;
    width: 16rem;
    text-decoration: none;
}

.RegistryGallery a:visited {
    display: block;
    width: 16rem;
    text-decoration: none;
}

.RegistryGallery a:active {
    display: block;
    width: 16rem;
    text-decoration: none;
}

.CallToActionButton {
    display: block;
    width: 16rem;
    padding: 0.6rem;
    background-color: #ac9b47;
    background-color: var(--color-primary);
    border: none;
    font-family: 'mr_eaves_sans';
    font-size: 1.375rem;
    text-align: center;
    text-decoration: none;
    letter-spacing: 0.8rem;
    color: white;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
}

.CallToActionButton .innerBorder {
    display: block;
    border: solid 0.125rem white;
}

.Footer {
    width: 100%;
    border-top: 1px solid #dad1a6;
    border-top: 1px solid var(--color-gentle);
    margin-top: 6rem;
    padding: 1rem 0;
    text-align: center;
    color: #dad1a6;
    color: var(--color-gentle);
}

@media (max-width: 880px) {
    .PageArea {
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        margin: 0;
        padding: 0 10%;
        max-width: none;
    }

    .HeaderLogo {
        display: none;
    }

    .HeaderMenuButton {
        display: block;
        margin: 0 0 -2.65rem 1rem;
    }

    .HeaderMenuButton div {
        content: '';
        display: block;
        width: 2rem;
        height: 0.5rem;
        background-color: #dad1a6;
        background-color: var(--color-gentle);
        color: #ac9b47;
        color: var(--color-primary);
        margin: 0 0 0.125rem 0;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
    }

    .HeaderLogo.mobile {
        display: block;
        padding: 1.65rem 0 1rem 0;
        width: 100%;
        background-color: white;
        background-color: var(--color-surface);
        box-shadow: 0 0 0.8rem #dad1a6;
        box-shadow: 0 0 0.8rem var(--color-gentle);
        cursor: pointer;
    }

    .HeaderLogo.mobile svg {
        display: block;
        margin: 0 auto;
    }

    .Nav {
        height: auto;
        box-sizing: border-box;
        position: absolute;
        width:100%;
        margin: 1px 0 0 0;
        padding: 1rem 0;
        -webkit-border-image: none;
                border-image: none;
        border-style: none;
        border-bottom: 1px solid #dad1a6;
        border-bottom: 1px solid var(--color-gentle);
        z-index:1;
        box-shadow: 0rem 0.9rem 0.8rem -0.7rem #dad1a6;
        box-shadow: 0rem 0.9rem 0.8rem -0.7rem var(--color-gentle);
    }

    .Nav.desktop {
        display: none;
    }

    .NavItem {
        box-sizing: border-box;
        width: 100%;
        padding: 0 13%;
        overflow: hidden;
    }

    .NavItemText {
        text-align: center;
        padding-right: 0;
    }

    .NavItem.selected::after {
        box-sizing: content-box;
        width: 136%;
        margin: -3.4rem 0 0 -18%;
    }

    /* Doesn't work perfectly because it only applies the zone to the end of first viewport height */
    .Nav.closeZone {
        content: '';
        height: calc(100% - 5rem);
        background: transparent;
        cursor: default;
        box-shadow: none;
        border: none;
    }
}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 2, 2019 */



@font-face {
    font-family: 'mr_eaves_sans';
    src: url(/static/media/mreavesmodotbookitalic-webfont.39d6b39e.woff2) format('woff2'),
         url(/static/media/mreavesmodotbookitalic-webfont.75db1fc6.woff) format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'mr_eaves_sans';
    src: url(/static/media/mreavesmodotbold-webfont.ad6fd6ad.woff2) format('woff2'),
         url(/static/media/mreavesmodotbold-webfont.dbf70059.woff) format('woff');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'mr_eaves_sans';
    src: url(/static/media/mreavesmodotbolditalic-webfont.7f54e06c.woff2) format('woff2'),
         url(/static/media/mreavesmodotbolditalic-webfont.5d9fa012.woff) format('woff');
    font-weight: bold;
    font-style: italic;

}




@font-face {
    font-family: 'mr_eaves_sans'; /*technically this is Mr Eaves Sans Book*/
    src: url(/static/media/mreavesmodotbook-webfont.988287e3.woff2) format('woff2'),
         url(/static/media/mreavesmodotbook-webfont.9d8b8e02.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'Butler';
    src: url(/static/media/Butler-UltraLight.2833f36f.woff2) format('woff2'),
        url(/static/media/Butler-UltraLight.022b3a54.woff) format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Butler';
    src: url(/static/media/Butler-Medium.242183bd.woff2) format('woff2'),
        url(/static/media/Butler-Medium.bebc169c.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Butler';
    src: url(/static/media/Butler-Light.e2009763.woff2) format('woff2'),
        url(/static/media/Butler-Light.9c4942d4.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Butler';
    src: url(/static/media/Butler-Black.3b7e5ae3.woff2) format('woff2'),
        url(/static/media/Butler-Black.eab2718a.woff) format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Butler';
    src: url(/static/media/Butler-Bold.2c2ef394.woff2) format('woff2'),
        url(/static/media/Butler-Bold.3a6cc52d.woff) format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Butler';
    src: url(/static/media/Butler-ExtraBold.b82f015e.woff2) format('woff2'),
        url(/static/media/Butler-ExtraBold.4e0506a8.woff) format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Butler';
    src: url(/static/media/Butler.c1a51301.woff2) format('woff2'),
        url(/static/media/Butler.2e991cdd.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}


.Schedule {
    width: 25.6875rem;
    margin-bottom: 6rem;
}

.Schedule h1 {
    font-family: 'mr_eaves_sans';
    font-weight: bold;
    text-align: center;
    font-size: 1.875rem;
}

.ScheduleItemsContainer {
    width: 4.21875rem;
    margin: auto;
    margin-top: 2rem;
}

.ScheduleItem {
    margin-bottom: 3rem;
}

.ScheduleItem .twoLiner {
    text-align: right;
    margin-top: -2.2rem;
    margin-left: -17rem;
    width: 15rem;
}

.ScheduleItem .time {
    color: var(--color-strong);
    font-size: 1.875rem;
    margin-left: 6.5rem;
    width: 5rem;
}

.ScheduleItem .dividingLine {
    width: 0.25rem;
    height: 2.2rem;
    padding-top: 0rem;
    padding-bottom: 3rem;
    margin: -4rem auto -2rem auto;
    background-color: black;
}

.ScheduleItem .floralMark {
    position: absolute;
    width: 4.21875rem;
    height: 2.5rem;
    background-image: url(/static/media/floral-1.a5ec4250.svg);
    background-size: 4.21875rem;
}

#Petal svg p {
    fill: black;
}
