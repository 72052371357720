.Schedule {
    width: 25.6875rem;
    margin-bottom: 6rem;
}

.Schedule h1 {
    font-family: 'mr_eaves_sans';
    font-weight: bold;
    text-align: center;
    font-size: 1.875rem;
}

.ScheduleItemsContainer {
    width: 4.21875rem;
    margin: auto;
    margin-top: 2rem;
}

.ScheduleItem {
    margin-bottom: 3rem;
}

.ScheduleItem .twoLiner {
    text-align: right;
    margin-top: -2.2rem;
    margin-left: -17rem;
    width: 15rem;
}

.ScheduleItem .time {
    color: var(--color-strong);
    font-size: 1.875rem;
    margin-left: 6.5rem;
    width: 5rem;
}

.ScheduleItem .dividingLine {
    width: 0.25rem;
    height: 2.2rem;
    padding-top: 0rem;
    padding-bottom: 3rem;
    margin: -4rem auto -2rem auto;
    background-color: black;
}

.ScheduleItem .floralMark {
    position: absolute;
    width: 4.21875rem;
    height: 2.5rem;
    background-image: url('../svg/floral-1.svg');
    background-size: 4.21875rem;
}

#Petal svg p {
    fill: black;
}